a {
  color: inherit;
  text-decoration: none;
}

@mixin link-opacity {
  transition: opacity var(--link-transition);

  @include hoverAndActive {
    opacity: var(--link-transition-opacity);
  }
}
