.c-sci {}

.c-sci__header {
  max-width: 1000px;
  margin: 0 auto 64px auto;
  padding: 24px var(--margin-h) 0 var(--margin-h);

  @include mq-from('bigger') {
    max-width: 1200px;
  }

  @include mq('mobile') {
    padding-top: 40px;
  }
}

.c-sci__title {
  font-family: var(--f-mercure);
  font-size: 1.7rem;
  text-align: center;
  text-wrap: balance;
  margin-bottom: 1.7rem;

  @include mq('mobile') {
    font-size: 1.5em;
  }
}

.c-sci__subtitle {
  font-size: 1.3rem;
  text-align: center;
  text-wrap: balance;
  margin-top: -0.85rem;
  margin-bottom: 1.7rem;

  @include mq('mobile') {
    font-size: 32px;
  }
}

.c-sci__author {
  font-family: var(--f-karl);
  font-size: 1.3rem;
  text-align: center;
  text-wrap: balance;
  margin-bottom: 1.7rem;
}

.c-sci__issue {
  width: 100%;
  font-family: var(--f-karl);
  font-size: var(--fs-head);
  text-transform: lowercase;
  text-align: center;
  text-wrap: balance;
}

  .c-sci__issue-link {
    @include link-opacity;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.3rem;
    justify-items: center;

    @include mq('mobile') {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto);
    }
  }
  
.c-sci__feature {
  margin-bottom: 1.7rem;
}

.c-sci__section-title {
  font-family: var(--f-karl);
  font-size: var(--fs-section-title);
  margin-bottom: 1.2em; // 24px for 19px font size originally
  text-transform: lowercase;

  scroll-margin-top: var(--scroll-margin-top);
}

.c-sci__section {
  margin: 1.7rem auto;
}

.c-sci__section--black {
  color: #000;
}

.c-sci__section--narrow {
  max-width: var(--supplement-width);
  padding: 0 var(--margin-h);
}

.c-sci__section--wide {
  // margin: 1.7rem auto;
  max-width: var(--body-width);

  // Space for the side notes
  padding-right: calc(var(--fn-column-gap) + var(--fn-width) + var(--margin-h));
  padding-left: var(--margin-h);

  @include mq('mobile') {
    padding: 0 var(--margin-h);
  }
}
