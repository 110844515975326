.c-snapshot {
  font-family: var(--f-karl);
  font-size: var(--fs-head);
  max-width: var(--prose-width);
  padding: 0 var(--margin-h);
  margin: 1em auto 3em auto;
}

.c-snapshot__header {
  text-align: center;
  text-wrap: balance;
  hyphens: none;
}

.c-snapshot__link {
  display: block;
  
  .c-snapshot__date, .c-snapshot__title, .c-snapshot__subtitle, .c-snapshot__author, .c-snapshot__info {
    transition: opacity var(--link-transition);
  }

  @include hover {
    .c-snapshot__date, .c-snapshot__title, .c-snapshot__subtitle, .c-snapshot__author, .c-snapshot__info {
      opacity: var(--link-transition-opacity);
    }
  }
}

.c-snapshot__date {
  margin-bottom: .7em;
}

.c-snapshot__title {
  font-size: inherit;
}

.c-snapshot__author {
  margin-bottom: .7em;
}

.c-snapshot__info {
  margin-bottom: 1em;
}

.c-snapshot__feature {
  margin: 0;
  padding: 0;

  > picture {
    > img {
      max-height: 400px;
      object-fit: contain;
    }
  }
}

.c-snapshot__body {
  font-size: .8em;
  margin-top: 3em;

  p {
    margin-bottom: 1em;
    line-height: 1.25;
  }

  figure {
    @include mq-from('tablet') {
      margin: 2.5em auto;
      padding: 0 2.5em;
      width: fit-content;
    }

    @include mq('mobile') {
      margin: 1.5em 0;
      padding: 0;
      width: 100%;
    }
    
    // Single image
    > picture > img {
      display: block;
      max-height: 85vh;
      max-width: 100%;
    }

    // Multiple images
    > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 500px;

      > picture > img {
        height: 100%;
        width: auto;
        margin: 0 12px;
      }
    }

    > figcaption {
      font-size: var(--fs-captions);
      line-height: var(--fs-captions-lh);
      hyphens: none;

      margin-top: 10px;

      width: 0; // Prevents it from contributing to the container's width
      min-width: 100%; // Ensures it stretches to fill the space anyway

      > p {
        margin: 0;
      }
    }
  }
}