.s-prose-inline {
  font-family: var(--f-karl);

  a:not([class]) {
    @include link-opacity;
    text-decoration: underline;
    text-decoration-thickness: .07em;
    text-underline-offset: .15em;
  }

  sup {
    font-size: .9em;
    line-height: 0;
    font-feature-settings: none;
    vertical-align: .28em;
  }

  i > sup, em > sup {
    margin-left: .09em;
  }
}