.c-box {
  --c-box-bg: var(--c-bg);
  --c-box-fg: var(--c-fg);

  color: var(--c-box-fg);
  background-color: var(--c-box-bg);

  border: var(--border-width) solid var(--c-fg);
  border-radius: 24px;
  
  overflow: hidden;
  z-index: 100;

  position: relative;
  box-sizing: border-box;

  hyphens: none;
  
  @include mq('mobile') {
    border-radius: 18px;
  }
}

.c-box--inverted {
  --c-box-bg: var(--c-fg);
  --c-box-fg: var(--c-bg);
}

.c-box--transparent {
  --c-box-bg: transparent;
}

.c-box--no-border {
  border: none;
  background-color: transparent; // Fixes white borders on Safari
}

.c-box__link {
  z-index: 20;

  display: flex;
  flex-direction: column;
  justify-content: center;
  
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  font-family: var(--f-karl);
  text-align: center;
  text-wrap: balance;
  color: var(--c-bg);

  transition: opacity var(--link-transition);

  padding: 12px 24px;
  box-sizing: border-box;

  @include mq('mobile') {
    padding: 8px 18px;
  }

  @include mq-from('bigger') {
    padding: 24px 48px;
  }
}

.c-box__link--big {
  font-size: 1.2em;
}

.c-box__link--hover {
  opacity: 0;

  > span {
    z-index: 1;
  }

  @include hover {
    opacity: 1;
  }
  
  &:active {
    opacity: 1;
  }

  &::before {
    content: '';
    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: var(--c-fg);
    opacity: 0.8;
  }
}