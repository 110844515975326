@use "sass:list";
@use "sass:map";

$breakpoints: (
  'mobile': (0px, 639px),
  'tablet': (640px, 1199px),
  'desktop': (1200px, 1799px),
  'bigger': (1800px, null)
);

@mixin mq($from, $to: null) {
  @if $to == null {
    $to: $from;
  }

  @media (min-width: list.nth(map.get($breakpoints, $from), 1)) and (max-width: list.nth(map.get($breakpoints, $from), 2)) {
    & {
      @content;
    }
  }
}

@mixin mq-from($bp) {
  @media (min-width: list.nth(map.get($breakpoints, $bp), 1)) {
    & {
      @content;
    }
  }
}

@mixin mq-upto($bp) {
  @media (max-width: list.nth(map.get($breakpoints, $bp), 2)) {
    & {
      @content;
    }
  }
}

@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin hoverAndActive {
  @include hover {
    @content;
  }

  &:active {
    @content;
  }
}