.c-glider {
  scroll-behavior: smooth;
}

.c-glider--fullscreen {
  position: absolute;
  top: 0;
  left: 0;

  width: 100dvw;
  height: 100dvh;

  @include mq-from('tablet') {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  @include mq('mobile') {
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.c-glider--hijackscroll {
  @media (hover: hover) {
    @include mq-from('tablet') {
      transform: rotate(-90deg) translateY(-100dvh);
      transform-origin: top right;

      // Width and height are swapped
      width: 100dvh;
      height: 100dvw;
      overflow-x: hidden;
      overflow-y: scroll;

      .c-glider__wrapper {
        transform: rotate(90deg);

        // Width and height are not swapped
        width: auto;
        height: 100dvh;
      }
    }
  }
}

.c-glider--hijackscroll::-webkit-scrollbar {
  display: none;
}

.c-glider__wrapper {
  display: flex;

  @include mq-from('tablet') {
    flex-direction: row;
    height: 100%;
  }

  @include mq('mobile') {
    flex-direction: column;
    width: 100%;
  }
}

.c-glider__slide {
  position: relative;
  flex-shrink: 0;

  display: grid;

  @include mq-from('tablet') {
    grid-template: 100% / auto;
    height: 100%;
  }

  @include mq('mobile') {
    grid-template: auto / 100%;
    width: 100%;
  }
}

.c-glider__slide--text {
  @include mq-from('bigger') {
    width: 800px;
  }

  @include mq('desktop') {
    width: 600px;
  }

  @include mq('tablet') {
    width: 500px;
  }

  @include mq('mobile') {
    > .c-glider__desc {
      padding: calc(var(--margin-v) * 1.5) var(--margin-h);
    }

    .c-glider__slide--text + & {
      margin-top: calc(var(--margin-v) * -1.5 + 10px);

      > .c-glider__desc {
        padding-top: 0;
      }
    }
  }
}

.c-glider__picture {
  grid-area: 1 / 1 / 2 / 2;

  @include mq-from('tablet') {
    height: 100%;
    width: auto;
  }

  @include mq('mobile') {
    height: auto;
    width: 100%;
  }
}

.c-glider__picture--half {
  @include mq-from('tablet') {
    height: 50%;
  }
}

.c-glider__img {
  display: block;
  
  @include mq-from('tablet') {
    height: 100%;
    width: auto;
  }

  @include mq('mobile') {
    height: auto;
    width: 100%;
  }
}

.c-glider__desc {
  grid-area: 1 / 1 / 2 / 2;
  align-self: end;

  font-family: var(--f-karl);

  padding: var(--margin-v) var(--margin-h);

  @include mq-from('tablet') {
    font-size: var(--fs-prose-small);
  }

  @include mq('mobile') {
    font-size: var(--fs-prose);
  }

  p {
    @extend .s-prose-inline;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.c-glider__desc--center {
  text-align: center;
  hyphens: none;

  @include mq('mobile') {
    font-size: var(--fs-prose);
  }
}