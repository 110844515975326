@font-face {
  font-family: 'Mercure';
  src: url('../assets/fonts/Mercure-Regular.woff2') format('woff2'),
      url('../assets/fonts/Mercure-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
  size-adjust: 102%;
}

@font-face {
  font-family: 'Mercure';
  src: url('../assets/fonts/Mercure-Italic.woff2') format('woff2'),
      url('../assets/fonts/Mercure-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: block;
  size-adjust: 102%;
}

@font-face {
  font-family: 'Mercure Transcript';
  src: url('../assets/fonts/Mercure-Transcript.woff2') format('woff2'),
      url('../assets/fonts/Mercure-Transcript.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
  size-adjust: 102%;
}

@font-face {
  font-family: 'Karl';
  src: url('../assets/fonts/KarlST-Regular.woff2') format('woff2'),
      url('../assets/fonts/KarlST-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Karl';
  src: url('../assets/fonts/KarlBetaST-RegularItalic-calibrated.woff2') format('woff2'),
      url('../assets/fonts/KarlBetaST-RegularItalic-calibrated.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Karl Condensed';
  src: url('../assets/fonts/KarlCondensed-Bold.woff2') format('woff2'),
      url('../assets/fonts/KarlCondensed-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: block; // Fallback fonts mess with fitty
}
