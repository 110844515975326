.u-break-word {
  word-break: break-word;
}

.u-arrow {
  line-height: 0;
  vertical-align: -.1em;
}

.u-hideable {
  transition: opacity .2s linear;
}

.u-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s linear, visibility 0s linear .2s;
}