.c-committee {
  margin: 0;
}

.c-committee__person {
  break-inside: avoid;
}

.c-committee__name {
  break-after: avoid;
}

.c-committee__bio {
  margin-bottom: .5em;
  margin-left: 1.5em;
}
