.s-sci-bibliography {
  @extend .s-prose-inline;
  font-size: var(--fs-sci-prose-notes);

  @include mq-from('tablet') {
    columns: 2;
    column-gap: var(--fn-column-gap);
  }

  > h3 {
    column-span: all;
    font-size: 1em;
    text-transform: lowercase;
    text-wrap: balance;
    margin-bottom: var(--fn-row-gap);
    margin-top: var(--fn-row-gap); // = 2x fn-row-gap with <p> padding 
  }

  > h2 + h3 {
    margin-top: 0;
  }

  > p {
    padding-bottom: var(--fn-row-gap); // Works better than margin in Safari
    break-inside: avoid;
  }

  > .c-sci__section-title {
    column-span: all;
  } 
}

.s-sci-bibliography--marginfixbig {
  margin-bottom: calc(1.7rem - var(--fn-row-gap)); // To compensate for <p> padding
}

.s-sci-bibliography--marginfix {
  margin-bottom: calc(1rem - var(--fn-row-gap)); // To compensate for <p> padding
}