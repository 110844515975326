.c-error {
  padding: 0 var(--margin-h) var(--margin-v) var(--margin-h);
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-error__message {
  font-family: var(--f-karl-condensed);
  text-align: center;
  margin-bottom: .1em;
  text-wrap: balance;

  font-size: max(13.23vw, 66px); // = search box font size
  line-height: 1;
}