input {
  border: none;
  margin: 0;
  padding: 0;

  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

input::placeholder {
  color: inherit;
}

input:focus {
  outline: none;
}