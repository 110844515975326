.c-boxiss {
  background-color: transparent;
  transition: background-color var(--link-transition);

  @include hover {
    background-color: var(--c-bg);
  }
  
  &:focus-within {
    background-color: var(--c-bg);
  }

  @include mq('mobile') {
    padding: 2.8vw 4vw 4vw 4vw;
  }

  @include mq-from('tablet') {
    padding: 1.4vw 2vw 2vw 2vw;
  }

  @include mq-from('bigger') {
    padding: .8vw 1.2vw 1.2vw 1.2vw;
  }
}

.c-boxiss__number {
  font-family: var(--f-karl-condensed);
  text-transform: lowercase;
  font-size: 72px;
  line-height: .9;
  margin: 0;
}

.c-boxiss__title {
  font-family: var(--f-karl-condensed);
  text-transform: lowercase;
  font-size: 48px;
  line-height: .9;
  margin: 0;
}