.s-sci-prose-inline {
  font-family: var(--f-mercure);

  a:not([class]) {
    text-decoration: underline;
    text-decoration-thickness: .07em;
    text-underline-offset: .15em;
    @include link-opacity;
  }

  sup {
    font-size: inherit;
    vertical-align: baseline;
    font-feature-settings: "sups";
  }
}
