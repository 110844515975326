.c-about {
  padding: var(--margin-v) var(--margin-h);

  display: grid;
  grid-template-columns: repeat(4, 1fr);
 
  row-gap: 1.8rem;

  @include mq-from('desktop') {
    column-gap: 3rem;
  }

  @include mq('tablet') {
    column-gap: 2rem;
  }
}

.c-about__about {
  grid-column: 1 / 5;
  max-width: var(--prose-width);
  margin: 0 auto;
}

.c-about__section {
  > :last-child {
    margin-bottom: 0;
  }
}

.c-about__1c {
  @include mq('mobile') {
    grid-column: span 4;
  }

  @include mq-from('tablet') {
    grid-column: span 2;
  }
}

.c-about__2c {
  grid-column: span 4;

  @include mq-from('tablet') {
    columns: 2;
    column-gap: 2rem;
  }

  @include mq-from('desktop') {
    column-gap: 3rem;
  }
}

.c-about__1c-center {
  @include mq('mobile') {
    grid-column: span 4;
  }

  @include mq-from('tablet') {
    grid-column: 1 / 3;
  }
}

.c-about__title {
  font-size: inherit;
  text-align: left;
  text-transform: uppercase;
  column-span: all;

  margin-bottom: 1em;
}