.c-gototop {
  @extend .u-hideable;

  position: fixed;
  bottom: calc(var(--margin-v));
  right: calc(var(--margin-h));
  z-index: 1000;

  margin: 0;
  padding: 0;

  border: none;

  background-color: transparent;
  color: var(--c-fg);

  cursor: pointer;

  transition: opacity .2s linear;

  width: auto;

  @include mq-from('tablet') {
    height: 48px;
  }

  @include mq('mobile') {
    height: 38px;
  }

  @include hover {
    opacity: var(--link-transition-opacity);
  }
}

.c-gototop--left {
  @include mq-from('tablet') {
    transform-origin: bottom right;
    transform: rotate(-90deg) translateX(100%);
  }
}

.c-gototop__svg {
  display: block;
  height: 100%;
  width: auto;
  fill: var(--c-fg);
}