.c-boxbas {
  font-family: var(--f-karl);
  font-size: 48px;
  text-align: center;

  background-color: transparent;
  transition: background-color var(--link-transition);

  @include hover {
    background-color: var(--c-box-bg);
  }
  
  &:focus-within {
    background-color: var(--c-box-bg);
  }

  @include mq-from('bigger') {
    font-size: 72px;
  }
}

.c-boxbas__link {
  display: block;
  width: 100%;
  padding: .3em;
  box-sizing: border-box;
}