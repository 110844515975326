.c-tabs__header, .c-tabs__tabs {
  display: flex;
  gap: 24px;
  align-items: baseline;
}

.c-tabs__tab {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;

  font-family: var(--f-karl);
  color: var(--c-fg);
  text-transform: lowercase;
  font-size: var(--fs-section-title);

  cursor: pointer;
  @include link-opacity;
}

.c-tabs__tab--active {
  border-bottom: 0.1em solid var(--c-fg);
}

.c-tabs__content {
  display: none;
}

.c-tabs__content--active {
  display: block;
}