.c-seares {
  font-family: var(--f-karl);
  font-size: var(--fs);
  margin: 1em var(--margin-h);
}

.c-seares__section-title {
  font-size: inherit;
  margin: 2em 0 1em 0;
}

.c-seares__section-count {
  margin-left: .5em;

  &:before {
    content: '(';
  }

  &:after {
    content: ')';
  }
}

.c-seares__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.c-seares__item {
  padding: 0 0 0 0em;
  margin-bottom: 1em;
}

.c-seares__item-link {
  @include link-opacity;
  display: block;
}

.c-seares__item-link--media {
  display: grid;

  @include mq-from('tablet') {
    grid-template-columns: auto 1fr;
    column-gap: 1em;
  }

  @include mq('mobile') {
    grid-template-rows: auto auto;
    row-gap: .5em;
  }
}

.c-seares__title {
  text-wrap: balance;
}

.c-seares__tags {
  list-style-type: none;
  font-size: var(--fs-prose-small);
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  column-gap: 1em;
  margin-bottom: 12px;
}

.c-seares__tag {
  text-transform: lowercase;
}

.c-seares__caption {
  margin-top: 12px;
  font-size: var(--fs-prose-small);
}

.c-seares__img {
  border-radius: 12px;

  @include mq-from('tablet') {
    aspect-ratio: 1/1;
    width: 180px;
    height: auto;
    object-fit: cover;
    overflow: hidden;
  }

  @include mq-from('bigger') {
    width: 300px;
  }

  @include mq('mobile') {
    width: 100%;
    height: auto;
  }
}