.c-scitoc {
  font-size: var(--fs-sci-prose);
}

.c-scitoc__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  
  .c-scitoc__item > & {
    margin-top: .35em;
    padding-left: 1em;
  }
}

.c-scitoc__item {
  margin-bottom: .35em;
}

.c-scitoc__item--suppl {
  font-family: var(--f-karl);
  text-transform: lowercase;
}

.c-scitoc__item--text {
  font-family: var(--f-mercure);
  text-transform: none;

  & .c-scitoc__item--text {
    list-style-type: '—';
    list-style-position: outside;
    padding-left: .5em;
  }
}

.c-scitoc__link {
  @include link-opacity;
}