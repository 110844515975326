img {
  display: block;
  width: 100%;
  height: auto;
  -webkit-user-drag: none;
  user-select: none;
}

picture {
  display: block;
}

figure {
  margin: 0;
  scroll-margin-top: var(--scroll-margin-top);
}

img[loading=lazy] {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

img.loaded {
  opacity: 1;
}

// Applied to <picture>
@mixin picture-utilites {
  display: grid; // Better solution than position: relative
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  > img {
    grid-column: 1/2;
    grid-row: 1/2;
  }
}

.u-tint {    
  @include picture-utilites;
  isolation: isolate;
  background-color: var(--c-fg);

  > img {
    mix-blend-mode: screen;
    // filter: saturate(0); // Too slow on Safari, we desaturate server-side
  }
}

.u-vignette {
  @include picture-utilites;

  &::after {
    content: '';
    grid-column: 1/2;
    grid-row: 1/2;
    
    isolation: isolate;
    box-shadow:
      inset 0 120px 90px -90px var(--c-box-bg),
      inset 0 -120px 90px -90px var(--c-box-bg);
  }
}
