.c-snapnav {
  font-family: var(--f-karl);
  font-size: var(--fs-head);

  margin: 1em var(--margin-h) 2em var(--margin-h);
}

.c-snapnav__list {
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  gap: 1em;
  justify-content: center;
}

.c-snapnav__item {
  @include link-opacity;
}

.c-snapnav__item--active {
  @include rounded-underline;
}

.c-snapnav__item--hidden {
  visibility: hidden;
}