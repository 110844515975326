.c-site-header {
  @extend .u-hideable;

  padding: var(--margin-v) var(--margin-h);

  font-family: var(--f-karl);
  font-size: var(--fs-head);

  position: relative;
  z-index: 1000;
}

.c-site-header__site-name {
  font-size: inherit;
  margin-bottom: 4px;

  > a {
    @include link-opacity;
  }

  @include mq('mobile') {
    position: relative;
    z-index: 10;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    margin-bottom: 2px;
  }
}

.c-site-header__logo {
  width: 100%;
  margin-bottom: 1em;

  @include mq('mobile') {
    margin-bottom: .1em;
    grid-row: 1 / 2;
    grid-column: 1 / 3;
  }
}

  .c-site-header__logo > svg > g {
    fill: var(--c-fg);
  }

.c-site-header__menu {
  @extend .u-hideable;
  
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;

  @include mq('mobile') {
    font-size: min(6.2vw, var(--fs-head)); // Magic number to fit to screen width
    display: flex;
    justify-content: space-between;
  }
}

.c-site-header__menu-item {
  @include link-opacity;
  text-transform: lowercase;

  &:first-child {
    justify-self: left;
  }


  &:last-child {
    justify-self: right;
  }
}

.c-site-header__menu-item--active {
  @include rounded-underline;
}