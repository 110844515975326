:root {
  --f-karl: 'Karl', sans-serif;
  --f-karl-condensed: 'Karl Condensed', sans-serif;
  --f-mercure: 'Mercure', serif;
}

html, body {
  font-family: var(--f-mercure);
  font-size: var(--fs-head);
  color: var(--c-fg);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  hyphens: manual;
  -webkit-hyphens: manual;
}

p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: normal;
  scroll-margin-top: var(--scroll-margin-top);
}

@mixin rounded-underline {
  // From https://stackoverflow.com/a/39022038
  &:after {
    content: '';
    float: left;
    background-color: var(--c-fg); 
    width: 100%; 
    height: .08em; 
    border-radius: .04em;
  }
}