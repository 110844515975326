.c-home-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: min-content 1fr;
  gap: var(--margin-h);
  padding: 0 var(--margin-h) var(--margin-v) var(--margin-h);

  @include mq('mobile') {
    grid-template-columns: 1fr 1fr;
  }
}

.c-home-grid__box {
  place-self: start stretch;
}

.c-home-grid__2r {
  grid-row: span 2;

  @include mq-from('bigger') {
    grid-row: span 1;
  }
}

.c-home-grid__4c {
  grid-column: span 4;

  @include mq-from('bigger') {
    grid-row: span 4;
  }
}

.c-home-grid__6c {
  grid-column: span 6;

  @include mq-from('bigger') {
    grid-column: span 4;
  }
}

.c-home-grid__8c {
  grid-column: span 8;
}

.c-home-grid__8c-right {
  grid-column: 5 / 13;
}

.c-home-grid__10c {
  grid-column: span 10;
}

.c-home-grid__10c-right {
  grid-column: 3 / 13;
}

.c-home-grid__12c {
  grid-column: span 12;

  @include mq-from('bigger') {
    grid-column: 2 / 12;

    &.c-boxsearch {
      grid-column: span 12;
    }
  }
}

.c-home-grid__center {
  grid-column: 3 / 11;
}

.c-home-grid__align-end {
  align-self: end;
}

.c-home-grid__feature {
  grid-column: 1 / 13;

  > .c-vfigure {
    width: 100vw;
    right: var(--margin-h);
    will-change: transform;
  }
}

.c-home-grid__box, .c-home-grid__feature, .c-home-grid__12c {
  @include mq('mobile') {
    place-self: start stretch;
    grid-column: 1/3;
    grid-row: span 1;
  }
}