// BOX CONTENTS
// Separate from the box placement and color logic.

.c-boxart {
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
  text-align: center;

  @include mq('mobile') {
    padding: 8px 18px;
  }

  @include mq-from('bigger') {
    padding: 14px 32px;
  }
}

// Used when c-boxart is not giving the box its size (full image boxes)
.c-boxart--adapt {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

// Tags displayed at the top of the box
.c-boxart__tags {
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: flex;
  justify-content: center;
  margin-bottom: 18px;

  font-family: var(--f-karl);
  text-transform: lowercase;
  font-size: .6em;
}

  .c-boxart__tags > li {
    margin: 0 .5em;
  }

.c-boxart__title {
  margin: 0 0 18px 0;
  font-family: var(--f-mercure);
  font-size: 1.2em;
  text-wrap: balance;
}

.c-boxart__title--sans {
  font-family: var(--f-karl);
}

.c-boxart__title--condensed {
  font-family: var(--f-karl-condensed);
}

.c-boxart__info {
  font-family: var(--f-karl);
  font-size: .8em;
  text-wrap: balance;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  gap: .5em 1em;
}

  .c-boxart__info--vertical {
    flex-direction: column;
  }

.c-boxart__figure {
  margin: 24px 0;
}

.c-boxart__bottom {
  margin-top: auto;
  margin-bottom: 0;
}

.c-boxart__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}