.c-boxsearch {
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: baseline;

  // Magic numbers
  --c-search-bar-fs: max(13.23vw, 66px);;
  --c-search-bar-ph: .15em;
  --c-searh-bar--iconh: calc(var(--c-search-bar-fs) * 0.66);

  box-sizing: border-box;
  padding: 0 var(--c-search-bar-ph);

  font-size: var(--c-search-bar-fs);
  font-family: var(--f-karl-condensed);

  background-color: transparent;
  transition: background-color var(--link-transition);

  &:focus-within {
    background-color: var(--c-box-bg);
  }
}

.c-boxsearch__input {
  width: 100%;
  font-size: var(--c-search-bar-fs);
  line-height: 1.2; // 1.3 needed for ascenders and descenders
  // Was 1.15 but with a weird Chrome bug, it allows for vertical scroll, which
  // cannot be prevented by overflow-y: hidden.
  background-color: transparent;

  &::placeholder {
    color: inherit;
    opacity: 1;
    transition: opacity var(--link-transition);
  }

  &:focus::placeholder {
    opacity: 0;
  }
}

.c-boxsearch__btn {
  display: block;
  height: var(--c-searh-bar--iconh);

  background-color: transparent;
  border: none;
  cursor: pointer;

  // Adjust baseline
  position: relative;
  top: calc(var(--c-search-bar-fs) * 0.017);
}

.c-boxsearch__svg {
  display: block;
  height: 100%;
  width: auto;
  fill: var(--c-box-fg);
}