.c-footer {
  font-family: var(--f-karl);
  font-size: .6em;
  padding: var(--margin-v) var(--margin-h);

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @include mq('mobile') {
    grid-template-columns: repeat(2, 1fr);
  }
}

.c-footer__p {
  @include mq('mobile') {
    grid-column: span 2;
  }
}

.c-footer__links {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.c-footer__link {
  @include link-opacity;
}