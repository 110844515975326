.s-prose {
  @extend .s-prose-inline;
  
  font-size: var(--fs-prose);

  > p {
    margin-bottom: 1em;
  }

  > ul, > ol {
    list-style-position: outside;
    padding-left: .774em; // Karl em-dash is shorter than 1em
    margin: 0 0 1em 0;

    > li {
      padding-left: .726em;
      margin: .6em 0;
    }
  }

  > ul {
    list-style-type: '—';
  }
}

.s-prose--big {
  font-size: var(--fs-head);
}

.s-prose--small {
  font-size: var(--fs-prose-small);
}

.s-prose--italic {
  font-style: italic;

  i, em {
    font-style: normal;
  }
}