.c-vfigure {
  position: relative;
}

.c-vfigure--toFront {
  z-index: 200;
}

.c-vfigure__img {
  width: 100%;
}

.c-vfigure__caption {
  font-family: var(--f-karl);
  font-size: var(--fs-captions);

  margin: 10px auto 0 auto;

  text-align: center;
  text-wrap: balance;
  hyphens: none;

  @include mq-from('desktop') {
    width: 50vw;
  }

  @include mq('mobile') {
    padding: 0 var(--margin-h);
  }
}

.c-vfigure__button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // height: 100%; // Using aspect-ratio instead, as not to cover the caption

  padding: 0;
  margin: 0;

  border: 0;
  background-color: transparent;
  cursor: pointer;
}