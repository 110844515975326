html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  min-width: 100%;
  min-height: 100dvh;
  display: grid;
  grid-template-rows: auto 1fr auto;

  margin: 0;
  padding: 0;
  background-color: var(--c-bg);
}