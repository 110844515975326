.c-issue {
  --c-issue-bg: var(--c-bg);
  --c-issue-fg: var(--c-fg);

  font-size: var(--fs-head);

  background-color: var(--c-issue-bg);
  color: var(--c-issue-fg);
  padding: 2.2em var(--margin-h);
}

.c-issue:nth-child(even) {
  --c-issue-bg: var(--c-fg);
  --c-issue-fg: var(--c-bg);
}

.c-issue__title {
  font-family: var(--f-karl);
  font-size: inherit;
  margin: 0 0 2em 0;
}

.c-issue__abstract {
  margin: 0 0 2em 0;

  text-wrap: wrap; // Fallback for Safari
  text-wrap: pretty;

  @include mq-from('bigger') {
    max-width: 1800px;
  }

  @include mq-from('tablet') {
    padding-left: 2.5em;
  }

  @include mq('mobile') {
    font-size: var(--fs-sci-prose);
  }
}

.c-issue__toc-section-title {
  font-family: var(--f-karl);
  font-size: inherit;
  margin: 0 0 1em 0;
  text-transform: lowercase;
  padding-left: 0;
  text-wrap: balance;

  @include mq('mobile') {
    padding-left: 2em;
  }
}

.c-issue__toc-section-posttitle {
  display: block;
  text-transform: none;

  @include mq('mobile') {
    font-size: var(--fs-sci-prose);
    margin-top: .6em;
  }
}

.c-issue__toc-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 2em;

  &:last-child {
    margin-bottom: 0;
  }
}

.c-issue__toc-item {
  margin: 0 0 1em 2.5em;

  @include mq('mobile') {
    margin-left: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

  .c-issue__toc-link {
    display: block;
    @include link-opacity;
  }

  .c-issue__toc-link--disabled {
    cursor: not-allowed;
    opacity: var(--link-transition-opacity);
  }

  .c-issue__toc-tag {
    display: block;
    font-family: var(--f-karl);
  }
  
  .c-issue__toc-title {
    display: block;
  }

  .c-issue__toc-author {
    display: block;
    font-family: var(--f-karl);
  }