:root {
  --c-bg: white;
  --c-fg: rgb(95, 56, 15);

  --margin-v: 36px;
  --margin-h: 40px;

  --border-width: 4px;
  
  --fs-head: 36px;
  --fs-prose: 30px;
  --fs-prose-small: 24px;
  --fs-section-title: 19px;
  --fs-captions: 17px;
  --fs-captions-lh: 1.2;
  --prose-width: 900px;

  --fs-sci-prose: 22.5px;
  --fs-sci-prose-lh: 1.275;
  --fs-sci-prose-notes: 15px;
  --fs-sci-prose-notes-lh: 1.2;
  --fs-sci-prose-caption: 17px;
  --fs-sci-prose-caption-lh: 1.2;

  --body-width: 700px;
  --supplement-width: 650px;

  --fn-width: 300px;
  --fn-column-gap: 50px;
  --fn-row-gap: 18px;
  --fn-number-width: 30px;

  --link-transition: .2s linear;
  --link-transition-opacity: .7;

  --scroll-margin-top: 50px;

  @include mq-from('bigger') {
    --margin-v: 46px;
    --margin-h: 50px;

    --border-width: 6px;
    
    --fs-head: 46px;
    --fs-prose: 36px;
    --fs-prose-small: 28px;
    --fs-section-title: 22px;
    --fs-captions: 19px;
    --prose-width: 1200px;

    --fs-sci-prose: 24px;
    --fs-sci-prose-notes: 19px;
    --fs-sci-prose-caption: 21px;

    --body-width: 800px;
    --supplement-width: 750px;

    --fn-width: 400px;
    --fn-column-gap: 90px;
    --fn-row-gap: 24px;
    --fn-number-width: 40px;

  }

  @include mq('tablet') {
    --margin-v: 26px;
    --margin-h: 30px;

    --fs-head: 30px;
    --fs-prose: 26px;
    --fs-prose-small: 20px;
    --fs-section-title: 19px;
    --fs-captions: 15px;
    --prose-width: 700px;

    --fn-column-gap: 30px;
    --fn-width: 20vw;
    --fn-number-width: 0;
  }

  @include mq('mobile') {
    --margin-v: 14px;
    --margin-h: 16px;

    --fs-head: 26px;
    --fs-captions: 15.6px;
    --fs-section-title: 16px;
    --fs-prose: 20px;
    --fs-prose-small: 18px;

    --fs-sci-prose: 20px;
    --fs-sci-prose-notes: 15.6px;
    --fs-sci-prose-caption: 15.6px;
  }
}