// Styles specific to the main container
.s-sci-prose {
  @extend .s-sci-prose-inline;

  @include mq-from('tablet') {
    --pad-s-sci-prose: 2.7em;
  }

  @include mq('mobile') {
    --pad-s-sci-prose: 1.5em;
  }

  font-family: var(--f-mercure);
  font-size: var(--fs-sci-prose);
  
  > h3, > h4, > h5, > h6 {
    font-size: inherit;
    text-wrap: balance;

    margin-top: 2.5em;
    margin-bottom: 18px;
  }

  > h3 + h4, > h4 + h5 {
    margin-top: 0;
  }

  > h3 {
    margin-left: var(--pad-s-sci-prose);
  }

  > h4, > h5 {
    display: list-item;
    list-style-type: '—';
    list-style-position: outside;
  }

  > h4 {
    margin-left: calc(var(--pad-s-sci-prose) + 1em + (var(--pad-s-sci-prose) - 1em)/2);
    padding-left: calc(var(--pad-s-sci-prose) - 1em - (var(--pad-s-sci-prose) - 1em)/2);
  }

  > h5 {
    margin-left: calc(var(--pad-s-sci-prose)*2 + 1em + (var(--pad-s-sci-prose) - 1em)/2);
    padding-left: calc(var(--pad-s-sci-prose) - 1em - (var(--pad-s-sci-prose) - 1em)/2);
  }
  
  > blockquote:not(.s-sci-prose__epigraph) {
    margin: 1em 0 1em var(--pad-s-sci-prose);
  }

  > figure {
    font-family: var(--f-karl);
    font-size: var(--fs-sci-prose-caption);
    line-height: var(--fs-sci-prose-caption-lh);

    float: right;
    clear: right;
    width: calc(var(--fn-width));
    margin-right: calc(0px - var(--fn-width) - var(--fn-column-gap));

    margin-bottom: 24px;

    @include mq('mobile') {
      margin: 32px 0;
      float: none;
      width: 100%;
    }

    > picture > img {
      margin-bottom: 10px;

      // Disabled, caused images to stay blurry on Safari.
      // See https://bugs.webkit.org/show_bug.cgi?id=27684
      // will-change: transform;
      transform-origin: bottom right;
      transition: transform .2s ease-in-out, opacity 200ms ease-in-out;
      cursor: pointer;
    }
  }

  > hr {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    border: none;

    &:after {
      content: '⁂';
      display: block;
      width: 100%;
      text-align: center;
    }
  }
}

// Styles shared by the main container and blockquotes
.s-sci-prose, .s-sci-prose > blockquote:not(.s-sci-prose__epigraph) {
  > p, > blockquote:not(.s-sci-prose__epigraph) > p {
    margin-bottom: 1em;
    line-height: 1.275;

    text-wrap: wrap; // Fallback for Safari
    text-wrap: pretty;

    @include mq('mobile') {
      width: 100%;
      overflow: auto;
    }
  }

  > ul, > ol, > ul ul, > ol ol {
    list-style-position: outside;
    padding-left: 1em;
    margin: 0 0 1em 0;

    > li {
      padding-left: 1em;
      margin: .6em 0;

      text-wrap: wrap; // Fallback for Safari
      text-wrap: pretty;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  > ul, > ul ul {
    list-style-type: '—';
  }

  > p:last-child, > ul:last-child, > ol:last-child, > blockquote:last-child {
    margin-bottom: 0;
  }
}

// Paragraph counter
.s-sci-prose--counter {
  counter-reset: paragraph-counter;
  --counter-margin: 20px;
}

.s-sci-prose--counter, .s-sci-prose--counter > blockquote:not(.s-sci-prose__epigraph) {
  @include mq-from('tablet') {
    @media (hover: hover) {
      > p, > ul, > ol {
        counter-increment: paragraph-counter;
        
        &::before {
          display: block;
          position: absolute;
          transform: translateX(-100%);

          content: '§\202f' counter(paragraph-counter);
          font-family: var(--f-karl);
          text-align: right;

          opacity: 0;
          transition: opacity var(--link-transition);
        }

        &:hover {
          &::before {
            opacity: .2;
          }
        }
      }
    }
  }
}

// Tag-specific positioning
.s-sci-prose--counter {
  @include mq-from('tablet') {
    @media (hover: hover) {
      > p::before {
        padding-right: var(--counter-margin);
      }

      > blockquote:not(.s-sci-prose__epigraph) > p::before {
        padding-right: calc(var(--pad-s-sci-prose) + var(--counter-margin));
      }

      > ul::before, > ol::before {
        padding-right: calc(1em + var(--counter-margin));
      }

      > blockquote:not(.s-sci-prose__epigraph) > ul::before, > blockquote:not(.s-sci-prose__epigraph) > ol::before {
        padding-right: calc(var(--pad-s-sci-prose) + 1em + var(--counter-margin));
      }
    }
  }
}

.s-sci-prose__figRef {
  @include link-opacity;
  font-family: "Mercure Transcript";
  text-transform: uppercase;
  font-size: .8em;
  letter-spacing: -0.1em;
  word-spacing: -0.4em;
  padding: 0 0.25em;
}

.s-sci-prose__fn {
  text-wrap: nowrap;
}

.s-sci-prose__fn-ref {
  @include link-opacity;
  margin-left: 4px;
  font-feature-settings: "sups";
}

.s-sci-prose__fn-number {
  @include link-opacity;
  float: left;
  height: 0;
  margin-left: calc(0px - var(--fn-number-width));
  scroll-margin-top: var(--scroll-margin-top);

  @include mq('tablet') {
    display: block;
    float: none;
    height: auto;
    margin: 0 0 .4em 0;
  }

  @include mq('mobile') {
    display: none;
  }
}

.s-sci-prose__fn-content {
  font-size: var(--fs-sci-prose-notes);
  line-height: var(--fs-sci-prose-notes-lh);

  text-wrap: wrap; // Fallback for Safari
  text-wrap: pretty;

  // Margin for the note number
  box-sizing: border-box;

  @include mq-from('tablet') {
    float: right;
    clear: right;

    width: var(--fn-width);
    padding-left: var(--fn-number-width);
    margin-right: calc(0px - var(--fn-width) - var(--fn-column-gap));
    margin-bottom: var(--fn-row-gap);
  }

  @include mq('mobile') {
    float: left;
    overflow: hidden;
    width: 100%;

    // Closed state
    padding: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
  }
}

.s-sci-prose__fn-content--open {
  @include mq('mobile') {
    // Open state
    padding: 1em 0;
    visibility: visible;
    height: auto;
    opacity: 1;
  }
}

// Author tag
.s-sci-prose__author {
  text-align: right;
  margin-right: 1em;
}

  .s-sci-prose__author-bio {
    font-style: italic;
  }

// Epigraph tag
.s-sci-prose__epigraph {
  max-width: 45%;
  margin: 0 1em 2em auto;

  > p {
    font-size: .8em;
    margin-bottom: .5em;
  }
}